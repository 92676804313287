<template>
  <v-app>
    <dashboard-core-view :show-footer="showFooter" />
  </v-app>
</template>

<script>
  export default {
    name: 'PrintableDashboardIndex',

    components: {
      DashboardCoreView: () => import('./components/core/PrintableView'),
    },
    props: {
      showFooter: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      expandOnHover: false,
    }),
  }
</script>
